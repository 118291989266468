import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div name="home" className="bg-[#96B6C5] w-full h-screen">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-[#F1F0E8]">Hi, my name is</p>
        <h1 className=" text-4xl sm:text-7xl font-bold text-[#EEE0C9]">
          Kushal Bhargava
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold">
          I'm a Full Stack Developer.
        </h2>
        <p className=" py-4 max-w-[700px]">
          I'm a MERN full-stack developer with a passion for crafting seamless
          digital experiences that elevate user engagement and drive
          innovation.I thrive on solving complex problems through code and
          continuously exploring emerging technologies to stay at the forefront
          of web development.
        </p>
        <div>
          <Link to="work" smooth={true} duration={500}>
            <button className="text-white border-2 py-3 px-6 my-3 flex items-center hover:bg-[#ADC4CE] hover:border-[#ADC4CE]">
              View Work <HiArrowNarrowRight className="ml-3" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
