import React from "react";
import ChatApp from "../assets/projects/Chit-Chat-App.png";
import ToDo from "../assets/projects/Task-Manager.png";

const Work = () => {
  return (
    <div name="work" className="w-full md:h-screen bg-[#96B6C5] text-[#F1F0E8]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className=" pb-8">
          <p className=" text-4xl font-bold border-b-4 inline border-slate-600">
            Work
          </p>
          <p className="py-6">Recent projects that I build on MERN Stack.</p>
        </div>

        {/* Container */}
        <div className=" grid sm:grid-cols-2 gap-4">
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${ChatApp})` }}
            className=" shadow-lg shadow-[#EEE0C9] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className=" opacity-0 group-hover:opacity-100">
              <span className=" text-2xl font-bold tracking-wider">
                Chit-Chat App
              </span>
              <div className="pt-8 text-center">
                {/* <a href="/">
                  <button className=" text-center rounded-lg px-4 py-3 m-2 bg-[#96B6C5] font-bold text-lg">
                    Demo
                  </button>
                </a> */}
                <a href="https://github.com/haider0107/MERN-Chat-APP">
                  <button className=" text-center rounded-lg px-4 py-3 m-2 bg-[#96B6C5] font-bold text-lg">
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${ToDo})` }}
            className=" shadow-lg shadow-[#EEE0C9] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className=" opacity-0 group-hover:opacity-100">
              <span className=" text-2xl font-bold tracking-wider">
                {" "}
                TaskManager App
              </span>
              <div className="pt-8 text-center">
                {/* <a href="/">
                  <button className=" text-center rounded-lg px-4 py-3 m-2 bg-[#96B6C5] font-bold text-lg">
                    Demo
                  </button>
                </a> */}
                <a href="https://github.com/haider0107/Task-Manager">
                  <button className=" text-center rounded-lg px-4 py-3 m-2 bg-[#96B6C5] font-bold text-lg">
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
