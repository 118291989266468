import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { BsFillPersonLinesFill, BsMedium } from "react-icons/bs";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto flex justify-center items-center">
        <a
          href="https://www.linkedin.com/in/kushal-bhargava/"
          className="text-white mr-10 md:mr-14"
        >
          <FaLinkedin size={30} />
        </a>
        <a href="https://github.com/haider0107" className="text-white mr-10 md:mr-14">
          <FaGithub size={30} />
        </a>
        <a
          href="https://medium.com/@kushal.bhargava01"
          className="text-white mr-10 md:mr-14"
        >
          <BsMedium size={30} />
        </a>
        <a href="/" className="text-white">
          <BsFillPersonLinesFill size={30} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
