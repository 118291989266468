import React from "react";
import profile from "../assets/image.jpeg";

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-[#96B6C5] text-[#F1F0E8]">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-slate-600">
              About
            </p>
          </div>
          <div>
            <img
              src={profile}
              alt="profile"
              className="rounded-full mb-4 p-1 md:w-[50%] w-[75%] bg-[#ADC4CE] shadow-md shadow-[#EEE0C9]"
            />
          </div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm:text-right text-4xl font-bold">
            <p>Hi. I'm Kushal, nice to meet you. Please look around.</p>
          </div>
          <div>
            <p>
              With a proven proficiency in MERN technologies, I've recently
              designed and implemented full-stack applications that seamlessly
              integrate front-end and back-end functionalities. I'm eager to
              team up with other developers and designers, driven by a shared
              passion for creating impactful, user-centric software solutions. I
              thrive in dynamic environments that encourage creativity and
              learning, and I'm always ready to take on new challenges in the
              ever-evolving world of web development.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
