import React from "react";

import HTML from "../assets/html.png";
import JavaScript from "../assets/javascript.png";
import ReactImg from "../assets/react.png";
import Node from "../assets/nodejs.png";
import GitHub from "../assets/github.png";
import Tailwind from "../assets/tailwind.png";
import Mongo from "../assets/mongo.png";
import Express from "../assets/express-js.png";

const Skills = () => {
  return (
    <div name="skills" className="w-full h-screen bg-[#96B6C5] text-[#F1F0E8]">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
            <p className=" text-4xl font-bold inline border-b-4 border-slate-600">Skills</p>
            <p className="py-4">Technologies I have been working on. </p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8 uppercase text-black">
            <div className="bg-[#ADC4CE] shadow-md shadow-[#EEE0C9] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={HTML} alt="HTML icon" />
                <p className="my-4">HTML</p>
            </div>
            <div className="bg-[#ADC4CE] shadow-md shadow-[#EEE0C9] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={Tailwind} alt="Tailwind icon" />
                <p className="my-4">Tailwind</p>
            </div>
            <div className="bg-[#ADC4CE] shadow-md shadow-[#EEE0C9] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={JavaScript} alt="JavaScript icon" />
                <p className="my-4">JavaScript</p>
            </div>
            <div className="bg-[#ADC4CE] shadow-md shadow-[#EEE0C9] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={ReactImg} alt="React icon" />
                <p className="my-4">React</p>
            </div>
            <div className="bg-[#ADC4CE] shadow-md shadow-[#EEE0C9] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={GitHub} alt="Github icon" />
                <p className="my-4">Github</p>
            </div>
            <div className="bg-[#ADC4CE] shadow-md shadow-[#EEE0C9] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={Node} alt="Node icon" />
                <p className="my-4">Node</p>
            </div>
            <div className="bg-[#ADC4CE] shadow-md shadow-[#EEE0C9] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={Mongo} alt="Mongo icon" />
                <p className="my-4">Mongo DB</p>
            </div>
            <div className="bg-[#ADC4CE] shadow-md shadow-[#EEE0C9] hover:scale-110 duration-500">
                <img className="w-20 mx-auto" src={Express} alt="Express icon" />
                <p className="my-4">Express</p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
